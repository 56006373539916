import React from 'react';
//components
import Layout from '../components/Layout';
import TermsOfServiceDesktop from '../components/TermsOfService';
//helpers
//styles
import './style.css';

function TermsOfService() {
  return (
    <Layout
      mobile={<TermsOfServiceDesktop />}
      desktop={<TermsOfServiceDesktop />} />
  );
}

export default TermsOfService;