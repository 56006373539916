import React from 'react';
import { Container } from './styled.js';

function TermsAndConditions() {
  return (
    <Container>
      <h1 id="terms-and-conditions">Terms and Conditions</h1>
      <p>Thank you for using So Bridely! These Terms constitute a legally binding agreement between you and So Bridely and govern your access to and use of the Services and/or the Platform. You agree to be bound by these Terms.</p>
      <h5 id="-table-of-contents-"><strong>Table of contents</strong></h5>
      <ol>
        <li><a href="https://www.stillwhite.com/terms#sec1">General</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec2">Terms Of Service</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec3">Licence</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec4">Disclaimer</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec5">Platform Intellectual Property</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec6">Viruses</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec7">Registration</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec8">Using The Platform</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec9">Sales Listing Fee</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec10">Selling</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec11">Postage And Delivery</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec12">Buying</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec13">Your Obligations</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec14">Responsibility, Rights And Modification Of Member Content</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec15">Response Rating</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec16">Termination</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec17">Warranty And Limitation Of Liability</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec18">Indemnity</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec19">Disputes In Relation To The Services</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec20">Disputes Between Members</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec21">Links</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec22">Amendments</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec23">General Provisions</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec24">Privacy Policy</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec25">Additional Terms</a></li>
        <li><a href="https://www.stillwhite.com/terms#sec26">Definitions And Interpretation</a></li>
      </ol>
      <hr/>
      <h5 id="-1-general-"><strong>1. General</strong></h5>
      <p>So Bridely provides an online platform that connects Sellers with Buyers seeking to sell and/or buy wedding dresses (collectively, the “Services”). The Services are accessible through written communication with So Bridely, at <a href="https://www.sobridely.com">https://www.sobridely.com</a> and any other websites through which So Bridely makes the Services available (collectively, the “Site”) and any applications for mobile, tablet and other smart devices and application program interfaces which So Bridely makes available (collectively, the “Platform”).</p>
      <p>In these Terms and Conditions “you” or “your” means any unregistered user of the Platform or any Member of the Platform, as the context requires. If you are using the Platform, these Terms and Conditions are between you and So (“So Bridely”, “us” or “we”).</p>
      <p>Nothing in these Terms and Conditions obliges So Bridely to make the Services available at any time or by any technological or direct means.</p>
      <p>These Terms and Conditions incorporate our Privacy Policy and any other Policies, including the Seller Policy and the Buyer Policy, displayed on the Site or otherwise communicated by So Bridely from time to time to Members.</p>
      <p>In the event of inconsistency, these Terms and Conditions prevail over any Policies.</p>
      <p>WARNING: PLATFORM</p>
      <p>THE SITE AND SERVICES COMPRISE AN ONLINE PLATFORM BY WHICH SELLERS MAY CREATE SALES LISTINGS FOR GOODS AND BUYERS MAY LEARN ABOUT AND PURCHASE GOODS FROM SELLERS VIA ALL MAJOR CREDIT OR DEBIT CARDS. So Bridely DOES NOT OWN THE GOODS. So Bridely IS NOT A PARTY TO ANY SALE AND/OR PURCHASE OF GOODS BETWEEN MEMBERS AND HAS NO CONTROL AND DOES NOT VERIFY THE SUITABILITY OR RANKING OF THE MEMBER; THE TRUTH OR ACCURACY OF MEMBER CONTENT; SALES LISTINGS; ABILITY OF SELLERS TO SELL GOODS, ABILITY OF BUYERS TO PAY FOR GOODS; OR THAT A SELLER OR BUYER WILL ACTUALLY COMPLETE A TRANSACTION OR RETURN GOODS. THE SERVICES PROVIDED BY So Bridely IN CONNECTION WITH THE PLATFORM ARE LIMITED TO THE ENABLING OF THE SALE AND/OR PURCHASE OF GOODS (EXCLUDING THE COLLECTION AND REMITTANCE OF FEES AND DELIVERY AND RETURN OF GOODS) PURSUANT TO THESE TERMS AND CONDITIONS. So Bridely DISCLAIMS ALL LIABILITY IN THIS REGARD TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>
      <p>WARNING: COMPLIANCE WITH LAWS, FEES AND CHARGES</p>
      <p>MEMBERS ARE WHOLLY RESPONSIBLE FOR ENSURING THAT THEIR USE OF THE PLATFORM AND SALES AND/OR PURCHASE OF GOODS COMPLY WITH APPLICABLE REGULATIONS, LAWS AND THIRD-PARTY AGREEMENTS.</p>
      <p>YOU WILL BE WHOLLY RESPONSIBLE FOR ANY ADDITIONAL FEES AND CHARGES INCURRED BY YOU IN CONNECTION WITH THE PLATFORM AND/OR SALE AND/OR PURCHASE OF THE GOODS (WHETHER ARISING DIRECTLY OR INDIRECTLY).</p>
      <h5 id="-2-terms-of-service-"><strong>2. Terms of service</strong></h5>
      <p>The Platform is intended solely for persons who are 18 or older. Any access to or use of the Platform by anyone under 18 is expressly prohibited. By accessing or using the Platform you represent and warrant that you are 18 or older.</p>
      <p>By using the Platform, you agree to comply with and be legally bound by the terms and conditions of these Terms and Conditions of Service (“Terms”), whether or not you become a Member or user of the Services. These Terms govern your access to and use of the Platform and all Collective Content and constitute a binding legal agreement between you and So Bridely.</p>
      <p>If you are entering into these Terms on behalf of any other person and/or entity, you warrant and guarantee that you have the full right and authority to do so. If you do not have such authority, all your Site, Services and Platform activity will be considered a breach of these Terms.</p>
      <p>If you do not agree to these Terms, you have no right to obtain information from or otherwise continue using the Platform.</p>
      <h5 id="-3-license-"><strong>3. License</strong></h5>
      <p>By accepting these Terms, the Member is granted a limited, non-exclusive and revocable license to access the Platform in accordance with these Terms.</p>
      <p>You agree to only use the Platform and Materials for their intended legal purposes and shall not use any or all of them to engage in any conduct that is unlawful, immoral, threatening, abusive or in a way that is deemed unreasonable by So Bridely in its sole discretion.</p>
      <p>So Bridely may revoke, suspend or terminate the Member’s license in its absolute discretion for any reason that it sees fit, including for breach of these Terms by the Member or any of its users and where Member’s ratings are not considered acceptable by So Bridely. So Bridely will ordinarily advise the Member of any suspension or revocation however, it is under no obligation to do so.</p>
      <p>Your license to access the Platform and Materials shall be ongoing unless terminated by So Bridely in accordance with these Terms.</p>
      <h5 id="-3-disclaimer-"><strong>3. Disclaimer</strong></h5>
      <p>The information contained on the Platform is provided in good faith on an &quot;as is&quot; basis. So Bridely does not represent or warrant to the reliability, accuracy or completeness of the information contained on the Platform or in the Collective Content.</p>
      <p>You understand and agree that So Bridely is not a party to any agreements entered into between the Seller and the Buyer, nor is So Bridely a broker, agent or insurer. So Bridely has no control over:</p>
      <ol>
        <li>the content contained in any Sales Listing;</li>
        <li>the content contained in any Member Content;</li>
        <li>the existence, condition, quality, safety, legality, genuineness of any Goods;</li>
        <li>the truth or accuracy of any pictures or description of any Goods or any other content made available to Members;</li>
        <li>any discussions, negotiations and/or agreements between the Seller and the Buyer;</li>
        <li>any confirmed measurements sent by the Seller and/or the Buyer;</li>
        <li>any accuracy of the recommended retail price of the Goods;</li>
        <li>any negotiated price with respect to the Goods;</li>
        <li>the ability of Sellers to sell Goods;</li>
        <li>the ability of Buyers to purchase Goods;</li>
        <li>the ability of Buyers to pay for the Goods;</li>
        <li>the ability of the Seller and/or Buyer to actually complete a transaction or return Goods.</li>
      </ol>
      <p>So Bridely disclaims all liability in this regard. All sales and/or purchases are made or accepted at the Member’s own risk.</p>
      <p>If you choose to create an Account on the Platform, you understand and agree that your relationship with So Bridely is limited to being a Member, and not an employee, agent, joint venture or partner of So Bridely for any reason, and you act exclusively on your own behalf and for your own benefit, and not on behalf of or for the benefit of So Bridely. So Bridely does not control, and has no right to control, your sale and/or purchase of Goods, your offline activities associated with your sale and/or purchase of Goods, or any other matters associated with your sale and/or purchase of Goods that you provide and/or receive. As a Member, you agree not to do anything to create a false impression that you are endorsed by, partnering with, or acting on behalf of or for the benefit of So Bridely, including by using So Bridely’s Intellectual Property, without the prior written consent of So Bridely.</p>
      <p>Despite So Bridely facilitating the matching of Sellers with Buyers and Buyers with Sellers for the sale and/or purchase of Goods, So Bridely does not endorse any Seller, Buyer, Sales Listing and the sale and/or purchase of any Goods whatsoever. So Bridely’s matching of Sellers with Buyers and Buyers with Sellers is based solely on the information provided by the Seller and/or the Buyer in creating an Account or searching the Site. Sellers and Buyers are responsible for ensuring the accuracy, reliability and currency of all information supplied to each other and must immediately notify the other party if any information supplied changes or is inaccurate.</p>
      <p>By using the Platform, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties other than So Bridely will be limited to a claim against the particular Members or other third parties who caused you harm. You agree not to attempt to impose liability on or seek any legal remedy from So Bridely with respect to such actions or omissions. We encourage you to communicate directly with other Members on the Platform regarding any Sales Listing and/or sales of Goods by you and/or purchase of Goods made by you. This limitation shall not apply to any claim by a Seller against So Bridely regarding the remittance of payments received from a Member by So Bridely with respect to a Sales Listing, which instead shall be subject to the limitations described in the section below entitled “Warranty and Limitation of Liability”.</p>
      <p>Where So Bridely provides Policies on the Site for the co-operation and facilitation of the sale and/or purchase of Goods, So Bridely makes no representations and/or warranties in respect of those Policies and is in no way liable to enforce or comply with those Policies between Sellers and Buyers directly unless otherwise expressly stated.</p>
      <h5 id="-5-platform-intellectual-property-"><strong>5. Platform intellectual property</strong></h5>
      <p>You acknowledge and agree that the copyright in the Platform content and the software, design, text and graphics comprised in the Site and Platform, the selection and layout of the Site and Platform, the content and materials on the Site and Platform and all other Intellectual Property in connection with the Platform (together, the “Materials”) are owned by or licensed to us.</p>
      <p>You must not modify, copy, adapt, store in a retrieval system, reproduce, upload, post, transmit, sell, distribute in any way or communicate to the public, the Materials without our prior written consent.</p>
      <p>You must not frame or embed in another website any of the Materials without our prior written consent.</p>
      <p>You may store a reproduction of the content on the Site or Platform on your local computer for the sole purpose of viewing the content and Materials; and print hard copies of the content and Materials for the sole purpose of viewing, listing or purchasing Goods, but not for any other use.</p>
      <p>The Platform, the Materials and any ancillary materials or documents owned or used by So Bridely in connection with the Services and promotion of its business contains registered trademarks which are protected by law and other branding, images, content which constitute the Intellectual Property of So Bridely. You must not use any of the marks or trademarks appearing on the Platform and/or on or within Materials or our name or the names of our related bodies corporate or any of our Intellectual Property without our prior written consent.</p>
      <h5 id="-6-viruses-"><strong>6. Viruses</strong></h5>
      <p>So Bridely does not represent or warrant that any information, files or Collective Content obtained from, through or in connection with the Platform is free from viruses or other faults or defects. You are responsible for scanning any information, files or Collective Content for viruses.</p>
      <p>You agree that So Bridely has no responsibility or liability to you or any other person for any loss or damage (whether direct, indirect, consequential or economic), regardless of cause, negligence or otherwise, which may be the direct or indirect result of any virus, fault or defect transmitted from, through or in connection with the Platform or the Collective Content. In any event, if So Bridely is found to be liable this will be limited to the cost of supplying the information, files or Collective Content again.</p>
      <h5 id="-7-registration-"><strong>7. Registration</strong></h5>
      <p>The Platform may be used to facilitate the sale and/or purchase of Goods between Sellers and Buyers.</p>
      <p>When registering on the Platform and creating an Account, you may use the Platform as a Seller or Buyer. To access our Services, you must set up an Account with your name, email and a password. So Bridely encourages you to use a strong password (passwords that use a combination of upper and lower case letters, numbers and symbols).</p>
      <p>You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party. Accounts are personal to a Member and can only be accessed by the Member and/or their personnel. You agree to take sole responsibility for any activities or actions under an Account, whether or not you have authorized such activities or actions.</p>
      <p>So Bridely reserves the right to accept or reject your request to register for an Account at its sole discretion and So Bridely may request additional information from you before accepting or rejecting your request to register for an Account. Failure to provide such information is likely to result in rejection of your request.</p>
      <h5 id="-8-using-the-platform-"><strong>8. Using the Platform</strong></h5>
      <p>The Member acknowledges and agrees that:</p>
      <ol>
        <li>the Member will not breach any laws, third party rights, these terms and conditions and/or any Policies.</li>
        <li>the Buyer will not fail to pay for items purchased by the Buyer, unless the Buyer has a valid reason (i.e. the Buyer cannot contact the Seller).</li>
        <li>the Member will not post false, inaccurate, misleading, deceptive or defamatory content.</li>
        <li>the Member will not transfer the Account to another party.</li>
        <li>the Member will not infringe any Intellectual Property Rights belonging to So Bridely and/or any other third party or post content that does not belong to you.</li>
      </ol>
      <h5 id="-9-sales-listing-fee-"><strong>9. Sales Listing Fee</strong></h5>
      <p>The fee we charge for using our Services is the Sales Listing Fee.</p>
      <p>To create a Sales Listing, you may be asked a variety of questions about the Goods. Sales Listings will be made publicly available via the Platform. Buyers will be able to purchase your Goods via the Platform based upon information provided in your Sales Listing.</p>
      <p>On submitting a request for a Sales Listing on the Platform, you will be required to supply payment information to So Bridely (or an authorized billing representative of So Bridely). This payment information may include, for example, credit/debit card details (“Payment Details”). All Payment Details supplied to So Bridely will be retained and used by So Bridely in accordance with these Terms and the Privacy Policy available on the Site and Platform. You must ensure that the Payment Details you supply are correct and complete. Failure to pay the Sales Listing Fee as and when due to So Bridely under these Terms may result in the rejection of your request for a Sales Listing and/or your Account being suspended or terminated.</p>
      <p>All fees and charges shown on the Platform and identified in these Terms are in United States Dollars (USD)</p>
      <p>If the Seller cancels a Sales Listing once paid for by the Buyer, the Seller will not be entitled to a refund of the Sales Listing Fee on a change of mind basis.</p>
      <h5 id="-10-selling-"><strong>10. Selling</strong></h5>
      <p>As a Seller, you may create a Sales Listing for each sale of Goods.</p>
      <p>When listing Goods, the Seller acknowledges and agrees that:</p>
      <ol>
        <li>the Seller is responsible for the accuracy and content of the Sales Listing and Goods offered.</li>
        <li>the Seller will include the condition of the Goods to be sold, complete and accurate details of the Goods and describe any defects and/or flaws in the Goods.</li>
        <li>the Seller must only include one item in one Sales Listing and must not change the listing to another item.</li>
        <li>the Seller will include large and clear photographs of the actual Goods for sale and of any defects and/or flaws in the Goods.</li>
        <li>the Seller must not use copyright photographs without permission or use photographs that do not accurately represent the item for sale.</li>
        <li>the Seller must provide an accurate retail price of the Goods, minus taxes and alterations.</li>
        <li>the Seller must not provide an inflated retail price to increase the discount offered or increase the asking price unless this is to fix an error or list any item for more than 90% of the retail price.</li>
        <li>the Sales Listing may not be immediately searchable for several hours;</li>
        <li>any Member Content which violates these terms and conditions, or any Policies may be modified and/or deleted at So Bridely’s sole discretion.</li>
      </ol>
      <p>So Bridely does not charge commission on any sale. </p>
      <p>So Bridely reserves the right, at any time and without prior notice, to remove or disable access to a Sales Listing for any reason.</p>
      <h5 id="-11-postage-and-delivery-"><strong>11. Postage And Delivery</strong></h5>
      <p>The Buyer acknowledges and agrees that:</p>
      <ol>
        <li>the Buyer is responsible for all postage and delivery costs, including insurance.</li>
        <li>So Bridely recommends that the Buyer appropriate insurance, at its cost, for the delivery of the Goods by the Seller, or on behalf of the Seller.</li>
        <li>So Bridely is not liable for any delays and/or stolen Goods and/or Goods lost in transit as the contract for the sale and/or purchase of Goods is between the Seller and the Buyer directly.</li>
      </ol>
      <h5 id="-12-buying-"><strong>12. Buying</strong></h5>
      <p>The Buyer acknowledges and agrees that:</p>
      <ol>
        <li>the Buyer is responsible for reading and understanding the Sales Listing before purchasing the Goods.</li>
        <li>the Buyer is entering into a legally binding contract to purchase an item when the Buyer commits to buy Goods.</li>
        <li>So Bridely does not transfer legal ownership of Goods from the Seller to the Buyer.</li>
      </ol>
      <h5 id="-13-your-obligations-"><strong>13. Your Obligations</strong></h5>
      <p>While it is not So Bridely’s intent to monitor your online communications between the Seller and the Buyer within the Platform, So Bridely reserves the right to remove any content that we become aware of and determine to be harmful, illegal or in breach of copyright laws or privacy laws. You may not use the Platform or all or part of any Collective Content for any unlawful activity nor for any activities that breach any laws, infringe a third party’s rights or are contrary to any relevant standards or codes or the Policies. You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Platform.</p>
      <p>You agree and warrant that:</p>
      <ol>
        <li>all information and data provided by you to So Bridely (including payment and contact details) or otherwise is true, accurate, complete and up to date;</li>
        <li>any person receiving, accessing or using the Platform or the Collective Content using your Account is authorized by you to do so;</li>
        <li>you have and will comply with all relevant laws relating to your use of the Platform and the Collective Content and your making of any Sales Listing;</li>
        <li>you will ensure that any login information and password that is used to access the Platform, or the Collective Content and the details of any account is kept in a safe and secure manner;</li>
        <li>you will promptly notify So Bridely if you are or become aware that there is or has been an unauthorized use of any of your account, or any other security breach relating to any Account;</li>
        <li>you are responsible for any costs associated with your access to or use of the Platform, any Sales Listings, the sale and/or purchase of Goods and/or any Collective Content including internet access fees; and</li>
        <li>you are responsible and liable for any person that uses your Account and/or password, to request a Sales Listing and any sales and/or purchases through the Platform.</li>
      </ol>
      <p>You must not and agree that you will not:</p>
      <ol>
        <li>use the Platform in a manner or way, or post to or transmit to or via the Platform any material, which interferes with other users or our other customers or defames, harasses, threatens, menaces or offends any person or which prevents any other person from using or enjoying the Platform;</li>
        <li>make fraudulent or speculative inquiries, orders or requests through the Platform;</li>
        <li>use another person’s details without their permission or impersonate another person when using the Platform;</li>
        <li>post or transmit to the Platform any obscene, indecent, inflammatory or pornographic material or any other material that may give rise to civil or criminal proceedings;</li>
        <li>use any robot, spider, site search and retrieval application or other mechanism to retrieve or index any portion of the Platform;</li>
        <li>modify, adapt, translate or reverse engineer any portion of the Platform;</li>
        <li>remove any copyright, trade mark or other proprietary rights notices contained in or on the Platform;</li>
        <li>reformat or frame any portion of the web pages that are part of the Platform;</li>
        <li>use the Platform to violate the security of any computer or other network or engage in illegal conduct;</li>
        <li>tamper with or hinder the operation of the Platform or the Collective Content nor knowingly transmit any viruses, worms, defects, trojan horses or similar disabling or malicious code to the Platform;</li>
        <li>violate any local, state, national or other law or regulation, or any order of a court, including without limitation tax regulations;</li>
        <li>recruit, or otherwise solicit any Member to join third party services, websites or applications that are competitive to So Bridely, without our prior written approval;</li>
        <li>use the Platform or the Collective Content other than in accordance with these Terms;</li>
        <li>ask Buyers for measurements for made-to-order Goods or promote any other business or service on the Platform.</li>
      </ol>
      <h5 id="-14-responsibility-rights-and-modification-of-member-content-"><strong>14. Responsibility, Rights And Modification Of Member Content</strong></h5>
      <p>So Bridely accepts no liability for Member Content.</p>
      <p>The Member is responsible for the accuracy, quality and legality of Member Content and the Member’s acquisition of it, and the users that create, access and/or use Member Content.</p>
      <p>By registering as a Member, requesting a Sales Listing, contributing, commenting, uploading images or files, inputting data, linking to data or files on third-party sites, or engaging in any other form of communication through the Platform you are creating Member Content and you are granting So Bridely a transferable, sub-licensable, royalty-free, perpetual, irrevocable, non-exclusive, unrestricted, worldwide license to:</p>
      <ol>
        <li>use, copy, sub license, adapt, transmit, publicly perform or display any such Member Content; and</li>
        <li>sub license to third parties the unrestricted right to exercise any of the foregoing rights granted with respect to the Member Content.</li>
      </ol>
      <p>This license shall include the right to exploit any proprietary rights in the Member Content, including but not limited to rights under publicity, copyright, trademark or patent laws under any relevant jurisdiction.</p>
      <p>So Bridely reserves the right to filter, modify, publish and permanently remove Member Content on the Site, Platform or in connection with the Services in its sole and unfettered discretion</p>
      <p>To the extent permitted by law, So Bridely accepts no liability for the accuracy of any information made available using the Platform. Any reliance on the information available through the Platform is at the Member’s own risk. So Bridely shall have no liability for any loss of Member Content.</p>
      <h5 id="-15-termination-"><strong>15. Termination</strong></h5>
      <p>These Terms operate for a term beginning on the date you access the Platform and ending when terminated in accordance with these Terms.</p>
      <p>Members may terminate their access to the Platform by ceasing to use the Platform and requesting cancelation of their Account to So Bridely by email to help@sobridely.com provided that any sale and/or purchase of Goods must be completed. Once terminated, an Account cannot be reactivated, except at So Bridely’s absolute discretion.</p>
      <p>So Bridely may suspend or terminate these Terms in its sole discretion at any time. Without qualifying the foregoing, So Bridely may immediately suspend, terminate or limit your access to and use of the Platform and (where relevant) your Account and any Collective Content if So Bridely suspects that you have committed a fraudulent act, and/or you have, or you intend to, breach these Terms.</p>
      <p>Any termination by So Bridely in circumstances where you are either in material default of your obligations under these Terms or are in default of a non-material obligation which you fail to remedy within five (5) days of being notified of the default by So Bridely, will be of immediate effect.</p>
      <p>In the event an agreement arising under or in connection with these Terms is terminated, So Bridely may take such steps as it deems necessary to ensure you cease using or accessing, or having any ability to use or access, the Platform and/ or any Collective Content, including but not limited to revoking your license and blocking your access.</p>
      <p>Members are not entitled to any compensation for any potential purchases and/or any Sales Listings that were canceled as a result of a suspension, deactivation or termination of your Account.</p>
      <h5 id="-17-warranty-and-limitation-of-liability-"><strong>17. Warranty And Limitation Of Liability</strong></h5>
      <p>To the maximum extent permitted by law, So Bridely does not warrant or represent the suitability of the Platform, Services, the Collective Content, the Sales Listing, the sale and/or purchase of Goods, the use of the Services or its Members for any purpose.</p>
      <p>So Bridely makes no express or implied warranties under these Terms and Conditions in relation to the Services.</p>
      <p>To the extent permitted by law, So Bridely is not liable to Members for any direct loss and damages, or for fines, penalties, taxes and any exemplary, aggravated of punitive damages, liquidated damages or indirect and consequential loss and damages arising in any way (including without limitation for loss of revenue, loss of profits, failure to realize expected profits or savings and any other commercial or economic loss of any kind) legal costs and expenses or loss or corruption of data, in contract, tort, under any statute or otherwise (including negligence) arising from or connected to the Platform, Services, the Collective Content, the Sales Listing, the sale and/or purchase of Goods, the use of the Services or its Members.</p>
      <p>To the maximum extent permitted by law, So Bridely’s liability for breach of the Consumer Guarantees, if the Consumer Guarantees apply at law, is limited to any one or more of the following, at the Supplier’s election:</p>
      <ol>
        <li>the replacement of the Services or the supply of equivalent Services;</li>
        <li>the payment of the cost of replacing the services or of acquiring equivalent services; or</li>
        <li>the payment of the cost of having the Services repaired.</li>
      </ol>
      <p>Any liability to you by So Bridely for loss or damage of any kind arising out of these Terms, the Platform, Services, the Collective Content, the Sales Listing, the sale and/or purchase of Goods, the use of the Services or its Members or in connection with the relationship established by any of them is reduced to the extent (if any) that you cause or contribute to the loss or damage. This reduction applies whether our liability is in contract, tort (including negligence), under any statute or otherwise.</p>
      <p>In any event, So Bridely’s liability under any warranty and whether in negligence or not, shall not exceed the price of any Sales Listing Fee paid to So Bridely.</p>
      <h5 id="-18-indemnity-"><strong>18. Indemnity</strong></h5>
      <p>YOU ASSUME ALL RESPONSIBILITY FOR YOUR USE OF THE PLATFORM, THE SERVICES, THE COLLECTIVE CONTENT, THE SALES LISTING, THE SALE AND/OR PURCHASE OF GOODS AND/OR INTERACTIONS BETWEEN MEMBERS AND INDEMNIFY AND HOLD HARMLESS So Bridely, AND ANY OF ITS ASSIGNS, AGENTS OR EMPLOYEES AGAINST ANY CLAIMS, LAWSUITS, DAMAGES, AND EXPENSES (INCLUDING BUT NOT LIMITED TO SOLICITOR’S FEES ON AN INDEMNITY BASIS).</p>
      <h5 id="-19-disputes-in-relation-to-the-services-"><strong>19. Disputes In Relation To The Services</strong></h5>
      <p>If you have a dispute in relation to the Services provided by So Bridely, please contact us at help@sobridely.com and in the first instance, attempt to resolve the dispute with us.</p>
      <h5 id="-20-disputes-between-members-"><strong>20. Disputes Between Members</strong></h5>
      <p>So Bridely does not own the Goods and is not a party to any sale and/or purchase of Goods between Members. Members must resolve any disputes between themselves.</p>
      <p>You may be entitled to a replacement or refund by the Seller for a major failure and for compensation for any other reasonably foreseeable loss or damage. You may also be entitled to have the Goods repaired or replaced by the Seller if the Goods fail to be of acceptable quality and the failure does not amount to a major failure.</p>
      <p>If the Goods are second-hand, age, price and condition may be considered.</p>
      <h5 id="-21-links-"><strong>21. Links</strong></h5>
      <p>The Platform may contain links to third-party websites or resources. You acknowledge and agree that So Bridely is not responsible or liable for the availability or accuracy of such websites or resources or the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by So Bridely of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the Collective Content, products or services on or available from such websites or resources.</p>
      <h5 id="-22-amendments-"><strong>22. Amendments</strong></h5>
      <p>So Bridely reserves the right, in its sole discretion, to amend these Terms and/or the Platform at any time. Any such amendments will be effective as soon and from the commencement of the following calendar month. By continuing to access or use the Platform after these Terms have been modified, you agree to be bound by the amendments to these Terms.</p>
      <h5 id="-23-general-provisions-"><strong>23. General Provisions</strong></h5>
      <p>If the whole or any part of a provision of these Terms is void, unenforceable or illegal in a jurisdiction it is severed for that jurisdiction. The remainder of these Terms has full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected. This clause has no effect if the severance alters the basic nature of these Terms or is contrary to public policy.</p>
      <p>You may not assign or transfer these terms and conditions without So Bridely’s prior written consent.</p>
      <p>A failure of So Bridely to enforce any right or provision of these terms and conditions will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will only be effective if in writing and signed by a duly authorized representative of So Bridely.</p>
      <p>These Terms are governed by the laws of California, United States. Each party submits to the non-exclusive jurisdiction of the courts of that place.</p>
      <h5 id="-24-privacy-policy-"><strong>24. Privacy Policy</strong></h5>
      <p>You may refer to the So Bridely Privacy Policy available on the Site.</p>
      <h5 id="-25-additional-terms-"><strong>25. Additional Terms</strong></h5>
      <p>These Terms are subject to any additional terms expressed as ‘policies’ or advertised on the Platform by So Bride.</p>
      <p>Any terms stated in your sale of the Goods and/or purchase of the Goods will form part of your agreement with another Member.</p>
      <h5 id="-26-definitions-and-interpretation-"><strong>26. Definitions And Interpretation</strong></h5>
      <p>In these Terms:</p>
      <p>“Account” means the Member account used by the Member to sign up to the Site and receive the Services.</p>
      <p>“Buyer” means a Member who requests and/or accepts to purchase Goods from a Seller.</p>
      <p>“Collective Content” means the Materials and the Member Content.</p>
      <p>“Consumer Guarantee” means a consumer guarantee as it applies to supplies made under this agreement</p>
      <p>“Goods” means the Goods of the Seller, to be sold by the Seller and/or purchased by the Buyer.</p>
      <p>“Intellectual Property” means any and all items in which Intellectual Property Rights subsist, existing now or in the future and whether or not registered or registrable in connection with the Materials and/or the Member Content as the context requires.</p>
      <p>“Intellectual Property Rights” means all current and future registered and unregistered rights and all renewals and extension of those rights in respect of copyright, marks, trade secrets, know-how, confidential information, patents, inventions and discoveries and all other intellectual property</p>
      <p>“Materials” means copyright in the Platform content and the software, design, text and graphics comprised in the Site and Platform, the selection and layout of the Site and Platform, the content and materials on the Site and Platform and all other Intellectual Property in connection with the Site and Platform.</p>
      <p>“Member” means an unregistered user of the Platform and a person or entity that signs-up to the Platform and has an Account, including but not limited to, Sellers and Buyers.</p>
      <p>“Member Content” means all content that a Member posts, uploads, publishes, submits, transmits, or includes in their Account, Sales Listing, or otherwise.</p>
      <p>“Platform” means the Site, or and any applications for mobile, tablet and other smart devices and application program interfaces which So Bridely makes available for the provision of the Services.</p>
      <p>“Policy or Policies” means the policy or policies displayed on the Site and updated from time to time.</p>
      <p>“Sales Listing” means a sales listing of the Seller’s which is an offer to sell Goods to Members.</p>
      <p>“Sales Listing Fee” means the sales listing fee to be paid by the Seller to So Bridely for the Sales Listing and/or use of the Services.</p>
      <p>“Seller” means a Member who offers and/or accepts to sell Goods to a Seller.</p>
      <p>“Services” means the online platform for the provision of services provided by So Bridely that connects Sellers with Buyers seeking to sell and/or buy wedding dresses.</p>
      <p>“Site” means <a href="http://www.sobridely.com">http://www.sobridely.com</a> and any other websites through which So Bridely makes the Services available.</p>
      <p>“Tax” or “Taxes” means any sales tax, customs and duties taxes, fees, that a party may be required by law to collect and remit to governmental agencies, and other similar municipal, state, federal and national indirect or other withholding and personal or corporate income taxes.</p>
      <p>“Terms” means these terms and conditions of service.</p>
    </Container>
  );
}

export default TermsAndConditions;